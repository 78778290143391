import React from 'react'
import { Link } from 'react-router-dom'
import { AppSettings } from './../config/app-settings.js'

export default class PosHome extends React.Component {
	static contextType = AppSettings;
	
	constructor(props) {
		super(props);

		this.state = {
			posMobileSidebarToggled: false,
			brandData: null,
			open: false
		}

		this.handleDineIn = this.handleDineIn.bind(this)
		this.isOpen = this.isOpen.bind(this)
	}

	isOpen(brandData = null){
		const brand = brandData ? brandData : this.state.brand
		const today = new Date()
		const weekday = ["0x0000001","0x0000010","0x0000100","0x0001000","0x0010000","0x0100000","0x1000000"]
		
		if (brand.loja.horarios){
			brand.loja.horarios.forEach(element => {
				let month = today.getMonth()+1
				month = month < 10 ? "0"+month : month
				const binario = '0x'+(element.dias >>> 0).toString(2).padStart(7, 0)
				const hi = new Date( month+"/"+today.getDate()+"/"+today.getFullYear()+" "+element.horario_inicial )
    			const hf = new Date( month+"/"+today.getDate()+"/"+today.getFullYear()+" "+element.horario_final )

				if(!!(weekday[today.getDay()] & binario) ){
					if (today > hi && today < hf){
						this.setState({open: true})
					}
				}
			})
		}
	}
  
	async fetchData(){
		const brandData = this.context.brand

		this.isOpen(brandData)
		this.setState({ brandData:brandData })
	}
	
	async componentDidMount() {
		this.context.handleSetHome(true);
		await this.fetchData()
	}

	componentWillUnmount() {
		this.context.handleSetHome(false);
	}

	handleDineIn(e){
		e.preventDefault()

		window.location.href = "/dinein"
	}
  
	render() {
		
		return (
			<div className="center-content mb-5">
				<div className={'pos pos-customer mb-5'} id="pos-customer"  style={{display: "block", background: "none"}}>
					
					{this.state.brandData &&  (
						<div style={{textAlign: "center", padding: "20px"}}>
							<img src={"https://grubupload.s3.amazonaws.com/"+this.state.brandData.imagem_perfil.imagem} width="100px" alt="" style={{borderRadius:"20px"}} />

							<div style={{width:"100%", padding:"5px", textTransform: 'uppercase'}}>
									{this.state.brandData && (
										<span className="btn btn-default disabled mt-2"> {this.state.brandData.loja.tipo} </span>
									)}
									
								</div>
							<h2 className='mt-2'>{this.state.brandData.nome_fantasia}</h2>

							<div className="row" style={{fontSize:"larger"}}>
								

							

								

								<div class="col-md-12 text-gray">
									<p className='h5'>{this.state.brandData && this.state.brandData.endereco && (
										this.state.brandData.endereco.rua+" "+this.state.brandData.endereco.numero
									)}

									{this.state.brandData && this.state.brandData.complemento && (
										" "+this.state.brandData.endereco.complemento
									)}<br></br>

									{this.state.brandData && this.state.brandData.endereco && (
										" "+this.state.brandData.endereco.bairro
									)}

									{this.state.brandData && this.state.brandData.endereco && this.state.brandData.endereco.cidade && (
										" - "+this.state.brandData.endereco.cidade.cidade
										
									)}

								    </p>
								</div>

								<div style={{width:"100%", padding:"5px",}}>
									{this.state.open ? (
										<span className="badge bg-green rounded-pill"> ABERTO </span>
									) : (
										<span className="badge bg-danger rounded-pill"> FECHADO </span>
									)}
								</div>



							</div>
						</div>
					)}
					
					<div  style={{margin: '10px'}}>

						<h3 className="mb-4">Links de Interesse:</h3>
				
						<div className="col-md-12 mb-3">
							<a href="/" style={{textDecoration:"none"}}>
								<div className="btn btn-default btn-lg" style={{"padding":"16px","width":"300px"}}> <img src="https://grubicons.grub.digital/favicon.ico" style={{width: "15px"}} /> VER CARDÁPIO</div>
							</a>
						</div>

						{this.state.brandData && this.state.brandData.loja.b_delivery ? (
							<div className="col-md-12 mb-3">
								<a href="/delivery" style={{textDecoration:"none"}}>
									<div className="btn btn-default btn-lg" style={{"padding":"16px","width":"300px"}}> <img src="https://grubicons.grub.digital/favicon.ico" style={{width: "15px"}} /> PEDIR NO DELIVERY</div>
								</a>
							</div>
						) : (<></>)}

						
						{this.state.brandData && this.state.brandData.loja.b_pedidos ? (
							<div className="col-md-12 mb-3">
								<a href="/dinein" onClick={this.handleDineIn} style={{textDecoration:"none"}}>
									<div className="btn btn-default btn-lg" style={{"padding":"16px","width":"300px"}}> <img src="https://grubicons.grub.digital/favicon.ico" style={{width: "15px"}} /> LEER QR CODE MESA </div>
								</a>
							</div>
						) : (<></>)}

						{this.state.brandData && this.state.brandData.endereco && (
							<div className="col-md-12 mb-3">
								<Link to="#" target="_blank" onClick={(e) => { e.preventDefault(); window.open("https://www.google.com/maps/dir/?api=1&destination="+this.state.brandData.endereco.rua+", "+this.state.brandData.endereco.cidade.cidade+", "+this.state.brandData.endereco.numero+"&travelmode=driving", '_blank')}} style={{textDecoration:"none"}}>
									<div className="btn btn-default btn-lg" style={{"padding":"16px","width":"300px"}}> <img src="https://grubicons.s3.us-east-1.amazonaws.com/chegar.png" style={{width: "15px"}} /> COMO CHEGAR </div>
								</Link>
							</div>
						)}


						<br></br>

						<div className="col-md-4 offset-md-4 mt-6 mb-4">
							<Link to="/" onClick={() => window.location.href = "https://grub.digital"} style={{textDecoration:"none", }}>
								<img src="https://grubicons.s3.us-east-1.amazonaws.com/powered-by-grub.png" style={{width: "100%", marginTop:"40px", maxWidth:"200px"}} />
							</Link>
						</div>

					</div>

					
				</div>			
			</div>
		)
	}
}