import React from 'react'
import { AppSettings } from '../../config/app-settings.js'

import { me, getEntrega, getCep, getEndereco } from './../../store/api.js';

import InputTextMask    from '../../components/atoms/InputTextMask'
import InputText    from '../../components/atoms/InputText'
import Label        from '../../components/atoms/Label'

export default class Endereco extends React.Component {
    static contextType = AppSettings

    constructor(props) {
        super(props)

        this.state = {
            criaEndereco: 0,
            possuiEntregaNovoEndereco: null,
            enderecoValido:null,
            enderecos:[],
            novoEndereco:{
				items:{}
			},
        }

        this.handlerCepNovoEndereco = this.handlerCepNovoEndereco.bind(this)
    }

    async handlerCepNovoEndereco(name, value){
		let data = this.state.novoEndereco
		
		if (name === "cep" && value.length === 9 && !value.includes("_") ){
			const endereco = await getCep(value)

			data.items["estado"] = endereco.uf
			data.items["cidade"] = endereco.cidade
			data.items["bairro"] = endereco.bairro
			data.items["rua"] 	 = endereco.rua
			data.items["cep"] 	 = endereco.cep
			data.items["lat"] 	 = endereco.lat
			data.items["lng"] 	 = endereco.lng

			this.setState({ data })
            this.setState({ possuiEntregaNovoEndereco: endereco ? endereco : false})
		} else if (name === "numero" && value.length > 0) {
            data.items[name] = value
            const endereco = await getEndereco(value+"+"+data.items["rua"].replaceAll(" ","+")+", "+data.items["cidade"].replaceAll(" ","+")+", "+data.items["estado"])
            const entrega =  await getEntrega(endereco.lat, endereco.lng)

			endereco.distancia 	= entrega.km
			endereco.id_zona  	= entrega.id
			endereco.tempo 		= entrega.tempo
			endereco.valor 		= entrega.valor

			data.items["lat"] 	 = endereco.lat
			data.items["lng"] 	 = endereco.lng

            this.setState({ data })
            this.setState({ enderecoValido: entrega ? true : false })
        } else if (value !== undefined) {
            data.items[name] = value
            this.setState({ data })
        }
	}

    async changeEndereco(endereco){
		const entrega  = await getEntrega(endereco.lat, endereco.lng)

		if(entrega) {
			endereco.id_zona = entrega.id
			endereco.distancia = entrega.km
			endereco.tempo = entrega.tempo
			endereco.valor = entrega.valor
	
			this.props.mudaEndereco(endereco)
		} else {
			this.setState({possuiEntregaNovoEndereco: false})
		}
	}

    async submitEndereco(){
		const data = this.state.novoEndereco
		data.token = this.context.client

		if (data.items.rua && data.items.rua.length > 3) {
            this.props.submitEndereco(data)
		}
	}

    async componentDidMount() {
        const token = JSON.parse(localStorage.getItem('cliente'))
		const usuario = await me({items: {token: token}})
        
        if (usuario.data.enderecos) {
			const enderecos = usuario.data.enderecos

            if (enderecos.length > 0) {
                this.setState({enderecos: enderecos})
            }
        }
    }
    
    render() {
        return (
            <div>
                <ul className="nav nav-pills mb-3">

                <li className="nav-item h5 m3">
                        <a href="#default-tab-1" className={this.state.criaEndereco === 1 ? "nav-link active" : "nav-link"} onClick={(e) => {e.preventDefault(); this.setState({criaEndereco: 1})} }>Meus Endereços</a>
                    </li>
                    
                    <li className="nav-item h5 mb">
                        <a href="#default-tab-1" className={this.state.criaEndereco === 0 ? "nav-link active" : "nav-link"} onClick={(e) => {e.preventDefault(); this.setState({criaEndereco: 0})} }>Novo Endereço</a>
                    </li>
                   
                </ul>
                
                {this.state.criaEndereco === 0 && (
                    <>
                        <h3 style={{textAlign:"center", fontSize:"14px"}}>Incluir novo endereço:</h3>

                        <div className="form-floating mb-15px">
                            <InputTextMask nome="cep" mask={"99999-999"} setFomrData={this.handlerCepNovoEndereco} />
                            <Label nome="cep" label="CEP"/>
                        </div>

                        <div className="form-floating mb-15px">
                            <InputText nome="endereco" setFomrData={this.handlerCepNovoEndereco} dadosIniciais={this.state.possuiEntregaNovoEndereco ? this.state.possuiEntregaNovoEndereco.rua : ''} />
                            <Label nome="endereco" label="Endereço"/>
                        </div>

                        <div className="form-floating mb-15px">
                            <InputText nome="numero" setFomrData={this.handlerCepNovoEndereco} />
                            <Label nome="numero" label="Número"/>
                        </div>

                        <div className="form-floating mb-15px">
                            <InputText nome="complemento" setFomrData={this.handlerCepNovoEndereco} dadosIniciais={this.state.possuiEntregaNovoEndereco ? this.state.possuiEntregaNovoEndereco.complemento : ''} />
                            <Label nome="complemento" label="Complemento ou ponto de referência"/>
                        </div>

                        <div className='row'>
                            <div className="col-md-12 mb-3">
                                {this.state.possuiEntregaNovoEndereco && !this.state.novoEndereco.items['numero'] && (
                                    <div className="alert alert-primary fade show" style={{margin:"0"}}>
                                        <p style={{textAlign:"center", margin:"0"}}> 
                                            Informe o número da rua para continuar.
                                        </p>
                                    </div>
                                )}

                                {this.state.enderecoValido === false && this.state.enderecoValido !== null && this.state.novoEndereco.items['numero'] && (
                                    <div className="alert alert-danger fade show" style={{margin:"0"}}>
                                        <p style={{textAlign:"center", margin:"0"}}> 
                                            Infelizmente não entregamos na sua região :(
                                        </p>
                                    </div>
                                )}

                                {this.state.enderecoValido && this.state.novoEndereco.items['numero'] && (
                                    <div className="alert alert-green fade show" style={{color: "#052f13", background:"#b7e2c5", borderColor: "#b7e2c5", margin:"0"}}>
                                        <p style={{textAlign:"center", margin:"0"}}> 
                                            Boas noticias, entregamos na sua região :)
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>

                        <button className="btn btn-primary btn-lg" style={{width: "100%"}} onClick={() => {this.submitEndereco()}}>
                            Salvar novo endereço
                        </button>
                    </>
                )}

                {this.state.criaEndereco === 1 && (
                    <div className="mb-3" style={{height:"25%", overflow: "hidden scroll"}}>
                        {this.state.enderecos.map((endereco) => (
                            <div className='col-12 mt-3' onClick={() => this.changeEndereco(endereco)} style={{border:"1px solid #dee2e6", borderRadius: "15px", padding:"10px",  "boxShadow":"0 0.5rem 1rem rgba(0, 0, 0, 0.075)",}}>
                                <div className='col-2' style={{ display:"inline-block"}}>
                                    {this.props.possuiEntrega.id === endereco.id ? (
                                        
                                        <p style={{marginTop: "10px", display: "inline-block", height: "18px", width: "18px", backgroundColor: "#0078FF", borderRadius: "50%"}}></p>
                                    ) : (

                                      
                                        
                                        <p style={{marginTop: "10px", display: "inline-block", border:"1px solid #333", height: "18px", width: "18px", borderRadius:"50%"}}></p>
                                    )}
                                </div>
                                <div className="col-8" style={{ display:"inline-block", "cursor":"pointer"}}>
                               
                                    <address>
                                  
                                      
                                     <p className='h5'>{endereco.rua}, {endereco.numero} - 
                                        {endereco.bairro} - {endereco.cep}</p> 
                                    </address>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        )
    }
}