import React from 'react'

import { AppSettings } from '../../config/app-settings.js'
import ComandaStatus from '../molecula/comanda-status.jsx'

import { getHistoricoCarrinho } from './../../store/api.js';

export default class DetalhePedido extends React.Component {
    static contextType = AppSettings

    constructor(props) {
        super(props)

        this.state = {
            historicoCarrinho:null,
            poolling:null,
            dataUser: JSON.parse(localStorage.getItem('dataUser'))
        }
    }

    async componentDidMount() {
        this.props.loading(true)
        
        const interval = setInterval(() => {
            getHistoricoCarrinho(this.context.historicoCarrinho.id).then(historico => {

                if (historico.data.status === "cancelada" || historico.data.status === "fechada") {
                    clearInterval(this.state.poolling)
                    localStorage.removeItem("comanda")
                }

                this.setState({ historicoCarrinho: historico.data })
                this.props.loading(false)
            })
        }, 2000)

        this.setState({poolling: interval})
	}

    componentWillUnmount() {
        clearInterval(this.state.poolling)
    }

    getValorPedido(comanda){
		return comanda.pedidos.reduce((a, item) => a += parseFloat(item.valor), 0 )
	}

    getValorVoucher(comanda){
		const total = this.getValorPedido(comanda) + parseFloat(comanda.pagamentos[0].valor_taxa_entrega)
		const desconto = (total * (comanda.voucher.valor / 100)) * -1

		return desconto.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
	}

    getValorComEntrega(comanda){
		const total = this.getValorPedido(comanda) + parseFloat(comanda.pagamentos[0].valor_taxa_entrega)

		if(comanda.voucher) {
			const desconto = total * (comanda.voucher.valor / 100)
			return (total - desconto).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
		} else {
			return total.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
		}
	}

    render() {
        const produtosHistorico = this.state.historicoCarrinho
        

        return (
            <>
                {produtosHistorico && (
                    <div>
                        <h3>Pedido #{produtosHistorico.id}</h3>

                        <div className="pos-table" style={{padding:"0"}}>

                            <ComandaStatus comanda={produtosHistorico}/>

                            {this.state.dataUser && this.state.dataUser.telefone && (
                                <div className="mb-3" style={{textAlign:"center"}}>
                                    <strong>Seu código de entrega é:</strong>
                                    <div style={{color:"#0078FF", fontSize:"28px"}}><strong>{this.state.dataUser.telefone.substring( this.state.dataUser.telefone.length - 4 )}</strong></div>
                                    <div>(os últimos 4 digitos de seu telefone)</div>
                                </div>
                            )}

                            {produtosHistorico.pedidos && produtosHistorico.pedidos.length > 0 && (
                                <div style={{background:"#DEE2E6", padding:"0 20px"}}>
                                    {produtosHistorico.pedidos.map( p => p.produtos.map( (item,i) => 
                                        <div key={"item_"+i} className="row pos-table-row">
                                            <div className="col-9">
                                                <div className="pos-product-thumb">
                                                    <div className="info">
                                                        <div className="title">{item.meta.pivot_quantidade} {item.nome.toUpperCase()}</div>
                                                        <div className="single-price">{item.meta.pivot_valor_produto}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-3 total-price">{(item.meta.pivot_valor_produto * item.meta.pivot_quantidade).toFixed(2)}</div>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {produtosHistorico.entrega && produtosHistorico.entrega.length > 0 && (
                                <div className="mt-4 mb-3">
                                    <strong>Endereço de entrega:</strong><br />
                                    {produtosHistorico.entrega[0].rua}, {produtosHistorico.entrega[0].numero} {produtosHistorico.entrega[0].bairro} - {produtosHistorico.entrega[0].cep}
                                </div>
                            )}

                            <hr style={{margin:"10px 0"}}/>

                            <div className="row">
                                <div className="col-6">Subtotal</div> 
                                <div className="col-6" style={{textAlign:"right"}}> <strong>{ this.getValorPedido(produtosHistorico).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) } </strong></div>
                            </div>

                            <div className="row">
                                <div className="col-6">Taxa de entrega</div>
                                <div className="col-6" style={{textAlign:"right"}}> <strong>{ parseFloat(produtosHistorico.pagamentos[0].valor_taxa_entrega).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) } </strong></div>
                            </div>

                            {produtosHistorico.voucher && (
                                <div className="row">
                                      <div className="col-8"  style={{color:"#0078FF"}}> <i className="fa-solid fa-tag"></i> Descontos e promoções</div> 
                                    <div className="col-4" style={{textAlign:"right", fontSize:"15px",}}> <strong>{  this.getValorVoucher(produtosHistorico)  } </strong></div>
                                </div>
                            )}

                            <div className="row">
                                <div className="col-6">Total</div> 
                                <div className="col-6" style={{textAlign:"right", fontSize:"15px", color:"green"}}> <strong>{ this.getValorComEntrega(produtosHistorico).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) } </strong></div>
                            </div>

                            <div className="row mt-3" style={{fontSize:"15px"}}>
                                <div className="col-6">Pagamento</div> 
                                <div className="col-6" style={{textAlign:"right"}}> <strong>{ produtosHistorico.pagamentos[0].tipo } </strong></div>
                            </div>

                            <hr style={{margin:"10px 0"}}/>

                            <div className="row">
                                <p style={{fontSize: "14px", textAlign: "center"}}>Possui alguma duvida ou tem algum problema com o pedido?</p>
                                <a href={"https://api.whatsapp.com/send?phone=55"+this.context.brand.loja.whatsapp} className="btn h5 btn-outline-green bt-lg"  target="_blank" rel="noreferrer"> 
                                <h5><i className="fa-brands fa-whatsapp"></i> Falar com o estabelecimento </h5>
                                </a>
                            </div>
                        </div>
                    </div>
                )}
            </>
        )
    }
}