import React from 'react'
import { Modal, ModalBody } from 'reactstrap'

import { doLogin, getEntrega, validaTelefone, getCep, novoCliente, validaCPF} from './../../store/api.js';

import { AppSettings } from '../../config/app-settings.js'
import InputTextMask    from '../atoms/InputTextMask'
import InputText    from '../atoms/InputText'
import Label        from '../atoms/Label'

import Lottie from "lottie-react"
import boasVindas from "../../assets/grub-boas-vindas.json"

export default class Login extends React.Component {
    static contextType = AppSettings;

    constructor(props) {
		super(props)

		this.state = {
            brandData:null,
            cliente:null,
            passoCriaConta: 1,
            criaConta:true,
            erroCadastro:null,
            dataUser:{items:{}},
            card:{
				cvc: '',
				expiry: '',
				focus:'number',
				name: '',
				number: '',
				token: '',
				cpf:''
			},
		}

        this.setUserData = this.setUserData.bind(this)
        this.salvarUser = this.salvarUser.bind(this)
        this.setLogin = this.setLogin.bind(this)
	}

    validatePhone (phone) {
        const regex = new RegExp(/\d\d(\d)\1{7,8}/)
        return !regex.test(phone.replace(/\D/g, ''))
    }

    validaCPF (strCPF) {
        let Soma = 0
        let Resto = 0
        let i = 0
        strCPF = strCPF.replace(/\D/g, '')

        console.log(strCPF)

        if (strCPF === "00000000000") return false
        
        for (i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i)
        Resto = (Soma * 10) % 11
    
        if ((Resto === 10) || (Resto === 11))  Resto = 0
        if (Resto != parseInt(strCPF.substring(9, 10)) ) return false
    
        Soma = 0
        for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i)
        Resto = (Soma * 10) % 11
    
        if ((Resto === 10) || (Resto === 11))  Resto = 0
        if (Resto != parseInt(strCPF.substring(10, 11) ) ) return false

        return true
    }

    async setLogin(nome, valor){
		const dataUser = this.state.dataUser

		dataUser.items[nome] = valor
		this.setState({dataUser: dataUser})
	}

    async componentDidMount() {
        this.setState({brandData:this.context.brand})
	}

    async changeWizard(e, stage){
		e.preventDefault()

		if (stage === 3){
			const data = this.state.dataUser

			if (Object.keys(data.items).length > 0) {
				let ok = true

				if(!data.items.nome) ok = false
                if(!data.items.cpf ) ok = false
                
				if(!data.items.telefone) ok = false
				
				if(!data.items.senha) ok = false

                if(!this.validaCPF(data.items.cpf)) {
                    ok = false
                    this.setState({erroCadastro: "CPF inválido. Por favor, verifique novamente."})
                }

                if(!this.validatePhone(data.items.telefone)) {
                    ok = false
                    this.setState({erroCadastro: "Telefone inválido. Por favor, verifique o número inserido."})
                }
				
				if (ok) {
					this.setState({passoCriaConta: stage})
				}
			}
		} else {
			this.setState({passoCriaConta: stage})
		}	
	}

    async doLogin(){
		const usuario = await doLogin(this.state.dataUser)

		if (usuario.data.usuario) {
			const dataUser = usuario.data.usuario
			const enderecos = usuario.data.enderecos
			const card = this.state.card

			if (enderecos.length > 0) {
				const endereco = enderecos[0]
				const entrega  = await getEntrega(endereco.lat, endereco.lng)

				endereco.id_zona = entrega.id
				endereco.distancia = entrega.km
				endereco.tempo = entrega.tempo
				endereco.valor = entrega.valor

				localStorage.setItem('possuiEntrega', JSON.stringify(endereco))
				this.setState({enderecos:enderecos, possuiEntrega: endereco})
			}

			if (dataUser.cards && dataUser.cards.length > 0 ){
				card.creditCardNumber = dataUser.cards[0].creditCardNumber
				card.creditCardToken = dataUser.cards[0].creditCardToken
				this.setState({ cards: dataUser.cards, card: card })
			}

            this.context.setClient(usuario.data.token)

			localStorage.setItem('cliente', JSON.stringify(usuario.data.token))
			localStorage.setItem('dataUser', JSON.stringify(dataUser))
			
			this.setState({cliente: usuario.data.token, dataUser: dataUser, passoCriaConta: null, criaConta:false })
		} else {
			this.setState({erroLogin: true})
		}
	}

    async setUserData(nome, valor){
		let telefone = false
        let cpf = false
		let userData = this.state.dataUser

		if (nome === 'telefone' && valor.length > 13){
			telefone = await validaTelefone({items: {telefone: valor}})

			if (telefone.data) this.setState({contaExiste: true, passoCriaConta:0, telefone:valor})
		}

        if (nome === 'cpf' && valor.length > 13){
			cpf = await validaCPF({items: {telefone: valor}})

			if (cpf.data) this.setState({contaExiste: true, passoCriaConta:0, cpf:valor})
		}

		if (nome === "cep" && valor.length === 9 && !valor.includes("_") ){
			const endereco = await getCep(valor)

			 this.setState({ possuiEntrega: endereco })
		}

		userData.items[nome] = valor

		this.setState({dataUser: userData})
	}

    async salvarUser(){
		const data = this.state.dataUser

		if (Object.keys(data.items).length > 0) {
			let ok = true

			if(!data.items.cep) ok = false
			if(!this.state.possuiEntrega || !this.state.possuiEntrega.rua != '') ok = false
			if(!data.items.numero) ok = false

            console.log(ok, data, this.state.possuiEntrega)

			if (ok) {
				data.items.rua = this.state.possuiEntrega.rua
				data.items.bairro = this.state.possuiEntrega.bairro
				data.items.cidade = this.state.possuiEntrega.cidade
				data.items.lat = this.state.possuiEntrega.lat
				data.items.lng = this.state.possuiEntrega.lng

				const cliente = await novoCliente(data)
                this.context.setClient(cliente.data.token)
				localStorage.setItem('cliente', JSON.stringify(cliente.data.token))
				this.setState({cliente: cliente.data.token, passoCriaConta: null, criaConta:false})
			}
		}
	}

    render() {
        const brand = this.context.brand
        const img = brand && brand.loja.imagem && brand.loja.imagem.imagem ? brand.loja.imagem.imagem : brand && brand.imagem_perfil ? brand.imagem_perfil.imagem : ""
		return (
            <>
                {!this.state.cliente && this.state.passoCriaConta === 0 && (
                    <Modal isOpen={this.state.criaConta} >
                        <ModalBody className="p-5" style={{textAlign: "center"}}>
                            <div className="row" style={{height: "80px", maxWidth: "180px", position: "relative", margin: "auto"}}>
                                <img alt="logo da grub" src="https://grubicons.s3.us-east-1.amazonaws.com/logo-grub.png" style={{width: "80px", borderRadius: "50%", position: "absolute", left: "15%"}} /> 
                                <img alt="logo da marca" src={"https://grubupload.s3.amazonaws.com/"+img} style={{width: "80px", borderRadius: "50%", position: "absolute", right: "15%"}} /> 
                            </div>

                            <h3>Logar na sua conta GRUB®</h3>
                            <p>Insira seus dados e acesse sua conta.</p>

                            {this.state.erroLogin && (
                                <p className="alert alert-danger fade show"> Sua tentativa de login falhou. Por favor, verifique sua senha e tente novamente. </p>
                            )}

                            {this.state.contaExiste ? (
                                <>
                                    <p className="alert alert-green fade show" style={{color: "#052f13", background:"#b7e2c5", borderColor: "#b7e2c5"}}> Verificamos que você já possui uma conta. Por favor realize seu login para continuar. </p>

                                    <div className="form-floating mb-15px">
                                        <InputTextMask nome="cpf" mask={"999.999.999-99"} dadosIniciais={this.state.cpf} setFomrData={this.setLogin} />
                                        <Label nome="cpf" label="CPF"/>
                                    </div>
                                </>
                            ) : (
                                <div className="form-floating mb-15px">
                                    <InputTextMask nome="cpf" mask={"999.999.999-99"} dadosIniciais={this.state.cpf} setFomrData={this.setLogin} />
                                    <Label nome="cpf" label="CPF"/>
                                </div>
                            )}

                            <div className="form-floating mb-15px">
                                <input type="password" className="form-control fs-15px" name="senha" autoComplete={'off'} required onChange={(e) => this.setLogin('senha', e.target.value)}/>
                                <Label nome="senha" label="Senha"/>
                            </div>
                            
                            <button className="btn btn-primary btn-lg me-1 mb-1" style={{width: "100%"}} onClick={() => {this.doLogin()}}>
                                Acessar Conta
                            </button>

                            <div className="row mt-3">
                                <div to="/" onClick={(e) => {e.preventDefault(); this.setState({passoCriaConta: 1});}} style={{fontSize: "larger", color:"#0078ff", textDecoration: "none","cursor":"pointer", textDecoration: "none", fontWeight: "600"}}>Não tenho conta, criar uma nova.</div>
                            </div>
                            
                            <div className="row">
                                <img alt="logo da grub" src="https://grubicons.s3.us-east-1.amazonaws.com/powered-by-grub.png" style={{width: "220px", margin: "30px auto 0 auto"}}/>
                            </div>
                        </ModalBody>
                    </Modal>
                )}

                {!this.state.cliente && this.state.passoCriaConta > 0 && (
                    <Modal isOpen={this.state.criaConta} >
                        {this.state.passoCriaConta === 1 && (
                            <ModalBody className="p-5" style={{textAlign: "center"}}>
                                <Lottie animationData={boasVindas} loop={true} style={{width: "80%", margin:"auto"}} />

                                <h3>Crie uma conta GRUB® e ganhe descontos e beneficios exclusivos.</h3>
                                <p>Você poderá usar sua conta em todos os estabelecimentos cadastrados.</p>

                                <p>Entre ou cadastre-se:</p>

                                <button className="btn btn-outline-black btn-lg" onClick={(e) => {this.changeWizard(e, 2)}}>
                                    <i className="fa fa-solid fa-circle-plus"></i>  Criar uma nova conta
                                </button>

                                <div className="row mt-3">
                                    <div to="/" onClick={(e) => {e.preventDefault(); this.setState({passoCriaConta: 0});}} style={{fontSize: "larger",color:"#0078ff", textDecoration: "none","cursor":"pointer", fontWeight: "600"}}>Logar na minha conta</div>
                                </div>

                                <div className="row">
                                    <img alt="logo da grub" src="https://grubicons.s3.us-east-1.amazonaws.com/powered-by-grub.png" style={{width: "220px", margin: '30px auto 0 auto'}}/>
                                </div>
                            </ModalBody>
                        )}

                        {this.state.passoCriaConta === 2 && (
                            <ModalBody className="p-5" style={{textAlign: "center"}}>
                                <div className="row" style={{height: "80px", maxWidth: "180px", position: "relative", margin: "auto"}}>
                                    <img alt="logo da grub" src="https://grubicons.s3.us-east-1.amazonaws.com/logo-grub.png" style={{width: "80px", borderRadius: "50%", position: "absolute", left: "15%"}} /> 
                                    <img alt="logo da marca" src={"https://grubupload.s3.amazonaws.com/"+img} style={{width: "80px", borderRadius: "50%", position: "absolute", right: "15%"}} /> 
                                </div>

                                <h3>Criar conta GRUB®</h3>
                                
                                <div className="nav-wizards-container" style={{overflow: 'hidden'}}>
                                    <nav className="nav nav-wizards-3 mb-2" style={{ width: '100%', minWidth: '10px'}}>
                                        <div className="nav-item col">
                                            <div className="nav-link completed">
                                                <div className="nav-dot"></div>
                                                <div className="nav-no">Passo 1</div>
                                                <div className="nav-text">Dados pessoais</div>
                                            </div>
                                        </div>

                                        <div className="nav-item col">
                                            <div className="nav-link">
                                                <div className="nav-dot"></div>
                                                <div className="nav-no">Passo 2</div>
                                                <div className="nav-text">Endereço</div>
                                            </div>
                                        </div>
                                    </nav>
                                </div>

                                {this.state.erroCadastro && (
                                    <p className="alert alert-danger fade show"> {this.state.erroCadastro} </p>
                                )}

                                <div className="form-floating mb-15px">
                                    <InputTextMask nome="cpf"  mask={"999.999.999-99"} setFomrData={this.setUserData}/>
                                    <Label nome="cpf" label="CPF"/>
                                </div>

                                <div className="form-floating mb-15px">
                                    <InputText nome="nome" setFomrData={this.setUserData} />
                                    <Label nome="nome" label="Nome"/>
                                </div>

                                <div className="form-floating mb-15px">
                                    <InputTextMask nome="telefone" mask={"(99)99999-9999"} setFomrData={this.setUserData} />
                                    <Label nome="telefone" label="Telefone"/>
                                </div>

                                <div className="form-floating mb-15px">
                                    <input type="password" className="form-control fs-15px" name="senha" required onChange={(e) => this.setUserData("senha", e.target.value)}/>
                                    <Label nome="senha" label="Senha"/>
                                </div>
                                
                                <button className="btn btn-primary btn-lg me-1 mb-1" style={{width: "100%"}} onClick={(e) => {this.changeWizard(e, 3)}}>
                                    Continuar
                                </button>
                            </ModalBody>
                        )}

                        {this.state.passoCriaConta === 3 && (
                            <ModalBody className="p-5" style={{textAlign: "center"}}>
                                <div className="row" style={{height: "80px", maxWidth: "180px", position: "relative", margin: "auto"}}>
                                    <img alt="logo da grub" src="https://grubicons.s3.us-east-1.amazonaws.com/logo-grub.png" style={{width: "80px", borderRadius: "50%", position: "absolute", left: "15%"}} /> 
                                    <img alt="logo da grub" src={"https://grubupload.s3.amazonaws.com/"+img} style={{width: "80px", borderRadius: "50%", position: "absolute", right: "15%"}} /> 
                                </div>

                                <h3>Criar conta GRUB®</h3>
                                
                                <div className="nav-wizards-container" style={{overflow: 'hidden'}}>
                                    <nav className="nav nav-wizards-3 mb-2" style={{ width: '100%', minWidth: '10px'}}>
                                        <div className="nav-item col">
                                            <div className="nav-link completed">
                                                <div className="nav-dot"></div>
                                                <div className="nav-no">Passo 1</div>
                                                <div className="nav-text">Dados pessoais</div>
                                            </div>
                                        </div>

                                        <div className="nav-item col">
                                            <div className="nav-link completed">
                                                <div className="nav-dot"></div>
                                                <div className="nav-no">Passo 2</div>
                                                <div className="nav-text">Endereço</div>
                                            </div>
                                        </div>
                                    </nav>
                                </div>

                                <div className="form-floating mb-15px">
                                    <InputTextMask nome="cep" mask={"99999-999"} setFomrData={this.setUserData} />
                                    <Label nome="cep" label="CEP"/>
                                </div>

                                {this.state.possuiEntrega && (
                                    <>
                                        <div className="form-floating mb-15px">
                                            <InputText nome="endereco" setFomrData={this.setUserData} dadosIniciais={this.state.possuiEntrega ? this.state.possuiEntrega.rua : ''} />
                                            <Label nome="endereco" label="Endereço"/>
                                        </div>

                                        <div className="form-floating mb-15px">
                                            <InputText nome="numero" setFomrData={this.setUserData} />
                                            <Label nome="numero" label="Numero"/>
                                        </div>

                                        <button className="btn btn-primary btn-lg me-1 mb-1" style={{width: "100%"}} onClick={() => {this.salvarUser()}}>
                                            Criar Conta
                                        </button>
                                    </>
                                )}

                                {this.state.possuiEntrega === false && this.state.possuiEntrega !== null && (
                                    <div to="/" style={{width:'100%', height:'100%', fontSize: "20px"}} className="btn btn-primary" >Veja o cardapio</div>
                                )}
                            </ModalBody>
                        )}
                    </Modal>
                )}
            </>
        )
    }
}