
import React from 'react'
import InputText    from '../../components/atoms/InputText'
import Label        from '../../components/atoms/Label'

import {me, removeCartao, removeEndereco} from './../../store/api.js';

export default class Perfil extends React.Component {
    constructor(props) {
		super(props)

		this.state = {
            token: JSON.parse(localStorage.getItem('cliente')),
			enderecos:[],
            cards:[],
            dataUser:null,
		}

        this.reloadUser = this.reloadUser.bind(this)
	}
    async reloadUser(){
        const token = JSON.parse(localStorage.getItem('cliente'))
		const usuario = await me({items: {token: token}})

		if (usuario.data.usuario) {
			const dataUser = usuario.data.usuario
			const enderecos = usuario.data.enderecos

			if (enderecos.length > 0) {
				this.setState({enderecos:enderecos})
			} else {
                this.setState({enderecos:[]})
            }
		
			if (dataUser){
				if (dataUser.cards && dataUser.cards.length > 0 ){
					this.setState({ cards: dataUser.cards })
				} else {
                    this.setState({ cards: []})
                }
			}

            this.setState({ dataUser:dataUser })
            this.props.loading(false)
		}
    }

	async componentDidMount() {
		this.reloadUser()
	}

    desloga(){
		localStorage.clear()
        window.location.reload()
	}

    async removeCartao(item){
		const data = {data: {items: { cardToken:item.creditCardToken, token: this.state.token}}}

		await removeCartao(data)

		this.reloadUser()
	}

	async removeEndereco(item){
		const data = {data: {items: { id:item.id, token: this.state.token}}}

		await removeEndereco(data)

		this.reloadUser()
	}

    render() {
        return (
            <>
                {this.state.dataUser && (
                    <div className="pos-sidebar" id="pos-sidebar">
                        <div className="pos-sidebar-header" style={{backgroundColor: "#0078FF"}}>
                            <div className="title"> Meu Perfil </div>
                        </div>

                        <div className="pos-sidebar-body tab-content" style={{padding:"30px 15px", overflow:"scroll", marginBottom:"70px"}}>

                            <div className="form-floating mb-15px">
                                <InputText nome="nome" className="form-control fs-15px" dadosIniciais={this.state.dataUser.nome} setFomrData={this.changeUserData} />
                                <Label name="nome" label="Nome"/>
                            </div>

                            <div className="form-floating mb-15px">
                                <input name="telefone" className="form-control fs-15px" readOnly value={this.state.dataUser.telefone} />
                                <Label name="telefone" label="Telefone"/>
                            </div>

                            <button className="col-12 mt-3 btn btn-primary  btn-lg me-1 mb-1" onClick={() => this.sendUserData()}>
                                Salvar alterações
                            </button>

                            <h4 className="mb-10px mt-5"  style={{color: "#0078FF"}}><b>Meus Endereços:</b></h4>

                            {this.state.enderecos.map((endereco) => (
                                <div className='col-12 mb-2'  style={{border:"1px solid #dee2e6", borderRadius: "10px", padding:"15px",  "boxShadow":"0 0.5rem 1rem rgba(0, 0, 0, 0.055)", }}>

<i className="col-5 fa-solid fa-location-dot" style={{marginRight: "5px", color:"#495057", fontSize:"22px",}}></i>
                                    
                                    <div className="col-8" style={{display:"inline-block", fontSize: "14px"}}>
                                  
                                        {endereco.rua}, {endereco.numero} {endereco.bairro} - {endereco.cep}
                                    </div>
                                    {this.state.enderecos.length > 1 && (
                                        <div className="col-2" style={{float: "right", textAlign: "right", padding: "8px 0"}} onClick={() => this.removeEndereco(endereco)}>
                                            <i className="fa-solid fa-trash" style={{color:"red", cursor:"pointer", fontSize: "15px"}}></i>
                                        </div>
                                    )}
                                </div>
                            ))}

                            <h4 className="mb-10px mt-5" style={{color: "#0078FF"}}><b>Meus Cartões:</b></h4>

                            {this.state.cards && this.state.cards.length > 0 && this.state.cards.map((i) => {
                                return (
                                    <div className='col-12 mt-3'  style={{border:"1px solid #dee2e6", borderRadius: "10px", padding:"15px", boxShadow:"3px", "boxShadow":"0 0.5rem 1rem rgba(0, 0, 0, 0.095)",}}>
                                        
                                        <div className="col-8"  style={{display:"inline-block", position:"relative"}}>
                                            <i className="col-5 fa-solid fa-credit-card" style={{marginRight: "5px", color:"#0078FF", fontSize:"22px", position:"absolute"}}></i>
                                            <div style={{marginLeft: "30px"}}>
                                                <p style={{margin: "0", fontSize: "14px"}}>***********{i.creditCardNumber}</p>
                                                <span className='badge bg-primary rounded-pill'>PADRÃO</span>
                                            </div>
                                        </div>
                                        <div className="col-2" style={{float: "right", textAlign: "right", padding: "8px 0"}} onClick={() => this.removeCartao(i)}>
                                            <i className="fa-solid fa-trash" style={{color:"red", cursor:"pointer", fontSize: "15px"}}></i>
                                        </div>
                                    </div>
                                )
                            })}

                            <button className="btn btn-outline-primary col-12 mt-5 mb-1" style={{color:"red", border:"none", background:"none", fontSize:"16px"}} onClick={() => this.desloga()}>
                                <strong>Deslogar conta</strong>  <i className="fa-solid fa-right-from-bracket"></i>
                            </button>
                        </div>
                    </div>
                )}
            </>
        )
    }
}