import axios from 'axios'

const verifyDays = (value) => {
  let result = false
  const d = new Date()

  const weekday = ["0x0000001","0x0000010","0x0000100","0x0001000","0x0010000","0x0100000","0x1000000"]
  const binario = '0x'+(value >>> 0).toString(2).padStart(7, 0)

  result = !!(weekday[d.getDay()] & binario)
  return result
}

const generateRandomString = (length) => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

const getBrand = () => {
  const host = window.location.host
  const parts = host.split(".")

  return parts[0].includes("localhost") ? "consigliere": parts[0]
}

export const getBrandData = async () => {
  //localStorage.clear()
  // let result = JSON.parse(localStorage.getItem('brand'))

  // if (!result) {
    const brand = await axios.get('https://xerxes.grub.digital/v1/site/'+getBrand())
    // result = brand

    // const d = new Date()
    // result.expire = d.setDate(d.getDate()+1)
    localStorage.setItem('brand', JSON.stringify(brand))
  // }
  

  return brand.data
}

export const getHistoricoCarrinho = async (id) =>{
    const comanda = await axios.get('https://xerxes.grub.digital/v1/site/'+getBrand()+'/comanda/'+id)
    localStorage.setItem('comanda', JSON.stringify(comanda))
    return comanda
}

export const getCardapio = async () => {
  const produtos = await axios.get('https://xerxes.grub.digital/v1/site/'+getBrand()+'/Produto')
 
  const result = produtos.data.filter((item) => {
    let r = item.dias ? verifyDays(item.dias) : true
    return r
  })

  return result
}

export const addPedido = async (data) => {
  const domain = 'https://xerxes.grub.digital/v1/site/'

  //, 'Authorization': 'Bearer '+Helper.getToken() : pensar em token valido por mesa para nao ter compra proposital errada

  const result = await axios.post(
    domain + getBrand() + '/pedido',
    data,
    { headers: { 'Content-Type': 'application/json' }}
  )

  return result
}

export const addVoucheComanda = async (data) => {
  const domain = 'https://xerxes.grub.digital/v1/site/'

  //, 'Authorization': 'Bearer '+Helper.getToken() : pensar em token valido por mesa para nao ter compra proposital errada

  const result = await axios.post(
    domain + getBrand() + '/addVoucher',
    data,
    { headers: { 'Content-Type': 'application/json' }}
  )

  return result
}

export const pagamento = async (data) => {
  const domain = 'https://xerxes.grub.digital/v1/site/'

  //, 'Authorization': 'Bearer '+Helper.getToken() : pensar em token valido por mesa para nao ter compra proposital errada

  const result = await axios.post(
    domain + getBrand() + '/pagamento',
    data,
    { headers: { 'Content-Type': 'application/json' }}
  )

  return result
}

export const getCodigoPix = async (data) => {
  const domain = 'https://xerxes.grub.digital/v1/site/'

  //, 'Authorization': 'Bearer '+Helper.getToken() : pensar em token valido por mesa para nao ter compra proposital errada

  const result = await axios.post(
    domain + getBrand() + '/codigoPIX',
    data,
    { headers: { 'Content-Type': 'application/json' }}
  )

  return result.data
}

export const validaStatusPagameto = async (data) => {
  const domain = 'https://xerxes.grub.digital/v1/site/'

  //, 'Authorization': 'Bearer '+Helper.getToken() : pensar em token valido por mesa para nao ter compra proposital errada

  const result = await axios.post(
    domain + getBrand() + '/pagamento/status',
    data,
    { headers: { 'Content-Type': 'application/json' }}
  )

  return result.data
}


export const addEndereco = async (data) => {
  const domain = 'https://xerxes.grub.digital/v1/site/'

  //, 'Authorization': 'Bearer '+Helper.getToken() : pensar em token valido por mesa para nao ter compra proposital errada

  const result = await axios.post(
    domain + 'endereco',
    data,
    { headers: { 'Content-Type': 'application/json' }}
  )

  return result
}

export const addDadosEntrega = async (data) => {
  const domain = 'https://xerxes.grub.digital/v1/site/'

  //, 'Authorization': 'Bearer '+Helper.getToken() : pensar em token valido por mesa para nao ter compra proposital errada

  const result = await axios.post(
    domain + getBrand() + '/entrega',
    data,
    { headers: { 'Content-Type': 'application/json' }}
  )

  return result
}


export const addCard = async (data) => {
  const domain = 'https://xerxes.grub.digital/v1/site/'

  //, 'Authorization': 'Bearer '+Helper.getToken() : pensar em token valido por mesa para nao ter compra proposital errada

  const result = await axios.post(
    domain + getBrand() + '/card',
    data,
    { headers: { 'Content-Type': 'application/json' }}
  )

  return result
}


export const chamaGarcon = async (id) => {
  const domain = 'https://xerxes.grub.digital/v1/site/'

  //, 'Authorization': 'Bearer '+Helper.getToken() : pensar em token valido por mesa para nao ter compra proposital errada

  const result = await axios.post(
    domain + getBrand() + '/garcon',
    {token:id},
    { headers: { 'Content-Type': 'application/json' }}
  )

  return result
}

export const getComanda = async (data) => {
  const domain = 'https://xerxes.grub.digital/v1/site/'

  //, 'Authorization': 'Bearer '+Helper.getToken() : pensar em token valido por mesa para nao ter compra proposital errada

  const result = await axios.post(
    domain + getBrand() + '/comanda',
    data,
    { headers: { 'Content-Type': 'application/json' }}
  )

  return result
}

export const getMesa = async (data) => {
  const domain = 'https://xerxes.grub.digital/v1/site/'

  const result = await axios.get(
    domain + getBrand() + '/mesa/'+data
  )

  return result
}

export const getHomeBanner = async () => {
  const domain = 'https://xerxes.grub.digital/v1/site/'

  const result = await axios.get(
    domain + getBrand() + '/home/banner'
  )

  return result
}

export const getDestaques = async () => {
  const domain = 'https://xerxes.grub.digital/v1/site/'

  const result = await axios.get(
    domain + getBrand() + '/Destaque'
  )

  return result
}

export const getComandaDelivery = async (data) => {
  const domain = 'https://xerxes.grub.digital/v1/site/'

  //, 'Authorization': 'Bearer '+Helper.getToken() : pensar em token valido por mesa para nao ter compra proposital errada

  const result = await axios.post(
    domain + getBrand() + '/delivery',
    data,
    { headers: { 'Content-Type': 'application/json' }}
  )

  return result
}

export const analise = async (data) => {
  const domain = 'https://xerxes.grub.digital/v1/site/'

  //, 'Authorization': 'Bearer '+Helper.getToken() : pensar em token valido por mesa para nao ter compra proposital errada

  const result = await axios.post(
    domain + getBrand() + '/analise',
    data,
    { headers: { 'Content-Type': 'application/json' }}
  )

  return result
}

export const confirmaPagamento = async (data) => {
  const domain = 'https://xerxes.grub.digital/v1/site/'

  //, 'Authorization': 'Bearer '+Helper.getToken() : pensar em token valido por mesa para nao ter compra proposital errada

  const result = await axios.post(
    domain + getBrand() + '/confirmaPagamento',
    data,
    { headers: { 'Content-Type': 'application/json' }}
  )

  return result
}

export const validaTelefone = async (data) => {
  const domain = 'https://xerxes.grub.digital/v1/site/'

  //, 'Authorization': 'Bearer '+Helper.getToken() : pensar em token valido por mesa para nao ter compra proposital errada

  const result = await axios.post(
    domain + getBrand() + '/telefone',
    data,
    { headers: { 'Content-Type': 'application/json' }}
  )

  return result
}

export const validaCPF = async (data) => {
  const domain = 'https://xerxes.grub.digital/v1/site/'

  //, 'Authorization': 'Bearer '+Helper.getToken() : pensar em token valido por mesa para nao ter compra proposital errada

  const result = await axios.post(
    domain + getBrand() + '/cpf',
    data,
    { headers: { 'Content-Type': 'application/json' }}
  )

  return result
}

export const me = async (data) => {
  const domain = 'https://xerxes.grub.digital/v1/site/'

  //, 'Authorization': 'Bearer '+Helper.getToken() : pensar em token valido por mesa para nao ter compra proposital errada

  const result = await axios.post(
    domain + getBrand() + '/me',
    data,
    { headers: { 'Content-Type': 'application/json' }}
  )

  return result
}

export const doLogin = async (data) => {
  const domain = 'https://xerxes.grub.digital/v1/site/'

  //, 'Authorization': 'Bearer '+Helper.getToken() : pensar em token valido por mesa para nao ter compra proposital errada

  const result = await axios.post(
    domain + getBrand() + '/dologin',
    data,
    { headers: { 'Content-Type': 'application/json' }}
  )

  return result
}

export const novoCliente = async (data) => {
  const domain = 'https://xerxes.grub.digital/v1/site/'

  //, 'Authorization': 'Bearer '+Helper.getToken() : pensar em token valido por mesa para nao ter compra proposital errada

  const result = await axios.post(
    domain + getBrand() + '/novocliente',
    data,
    { headers: { 'Content-Type': 'application/json' }}
  )

  return result
}

export const getCategorias = async (delivery = false) => {
  const tipo = delivery ? "delivery" : "dinein"
  const result = await axios.get('https://xerxes.grub.digital/v1/site/'+getBrand()+'/Categoria?filtro='+tipo)
  return result.data
}

export const minhasComanda = async (token) => {
  const result = await axios.get('https://xerxes.grub.digital/v1/site/'+getBrand()+'/comandas/'+token)
  return result.data
}

export const getOpcionais = async (produto) => {
  const result = await axios.get('https://xerxes.grub.digital/v2/site/'+getBrand()+'/Opcional?filtro='+produto)
  return result.data
}

export const getBanners = async () => {
  const result = await axios.get('https://xerxes.grub.digital/v1/site/'+getBrand()+'/Banner')
  return result.data
}

export const getCodigoInfo = async (codigo) => {
  const result = await axios.get('https://xerxes.grub.digital/v1/site/'+getBrand()+'/voucher/'+codigo)
  return result.data
}

export const getCep = async (cep) => {
  const result = await axios.get('https://xerxes.grub.digital/endereco/'+cep)
  return result.data
}


export const getEndereco = async (endereco) => {
  const result = await axios.get('https://xerxes.grub.digital/enderecocompleto/'+endereco)
  return result.data
}

export const acessos = async () => {
  const domain = 'https://xerxes.grub.digital/v1/site/'

  //, 'Authorization': 'Bearer '+Helper.getToken() : pensar em token valido por mesa para nao ter compra proposital errada

  const result = await axios.post(
    domain + getBrand() + '/acessos',
    {token:generateRandomString(20)},
    { headers: { 'Content-Type': 'application/json' }}
  )

  return result
}

export const getEntrega = async (lat, lng) => {
  const result = await axios.get('https://xerxes.grub.digital/v1/site/'+getBrand()+'/validaentrega/'+lat+'/'+lng)
  return result.data
}

export const removeCartao = async (data) => {
  await axios.delete('https://xerxes.grub.digital/v1/site/'+getBrand()+'/card', { data: data })
}

export const removeEndereco = async (data) => {
  await axios.delete('https://xerxes.grub.digital/v1/site/'+getBrand()+'/address', { data: data })
}

export const userData = async (data) => {
  const result = await axios.post('https://xerxes.grub.digital/v1/site/' + getBrand() + '/userData',
    {data}
  )

  return result
}

