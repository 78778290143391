import React from 'react'
import { Link } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {getCategorias, acessos} from '../store/api.js';

import ProductList from '../components/organismo/lista-produto'
import CtaCompra from '../components/organismo/cta-compra'
import SideBar from '../components/organismo/side-bar'
import Perfil from '../components/organismo/perfil'
import Pedidos from '../components/organismo/pedidos'
import SideBarDinein from '../components/organismo/side-bar-dinein'

import Lottie from "lottie-react"
import lottiesempedidos from "../assets/SBjEfz01yQ"

import { AppSettings } from '../config/app-settings.js'

export default class Cardapio extends React.Component {
	static contextType = AppSettings;

	constructor(props) {
		super(props)

		this.state = {
			delivery: false,
			carrinho: false,			
			brandData: null,
			lista:[],
			produtos:[],
			categorias:[],
			screenSize: "mobile",
			filter: null,
			filterSubcategory:null,
			showCart: false,
			modalPerfil: false,
			modalPedidos: false,
			transicao: false,
		}

		this.setDetalhe= this.setDetalhe.bind(this)
		this.handleCart = this.handleCart.bind(this)
		this.loading = this.loading.bind(this)
	}

	verifyDays = (value) => {
		let result = false
		const d = new Date()
	  
		const weekday = ["0x0000001","0x0000010","0x0000100","0x0001000","0x0010000","0x0100000","0x1000000"]
		const binario = '0x'+(value >>> 0).toString(2).padStart(7, 0)
	  
		result = !!(weekday[d.getDay()] & binario)
		return result
	  }

	async componentDidMount() {
		this.setState({brand: this.context.brand, screenSize: this.context.screenSize})
		this.setState({delivery: this.context.delivery, carrinho: this.context.carrinho})
		this.setState({showCart: this.context.screenSize === 'tablet' })

		await this.fetchData()
	}

	async fetchData(){
		const brandData = this.context.brand
		const categData = await getCategorias(this.context.delivery)
		const items = []
		let filtrado = []
		const queryParams = window.location.search

		if (this.context.dinein && this.context.mesaData) {
			const mesaData = this.context.mesaData

			if (mesaData.status === "fechada"){
				this.setState({ modalParaquedas: true})
			} else {
				this.setState({ mesaInfo: mesaData ? mesaData : null})
			}
			
		}
	
		const categorias = categData.filter((e) => {
			let result = false

			if ( e.produto.length > 0 ) result = true

			e.categorias.forEach((c) => {
				if ( c.produto.length > 0 ) result = true
			})

			return result
		})

		for (let i in categorias) {
			const p = categorias[i].produto
			const sub = categorias[i].categorias

			p.forEach(element => {
				if ((element.b_ativo && !this.state.delivery) || (element.b_delivery && this.state.delivery)) {
					if (element.dias && this.verifyDays(element.dias)) {
						items.push(element)
					} else if (!element.dias) {
						items.push(element)
					}
				}
			})

			sub.forEach(item => {
				const sp = item.produto

				sp.forEach(element => {
					if ((element.b_ativo && !this.state.delivery) || (element.b_delivery && this.state.delivery)) {
						if (element.dias && this.verifyDays(element.dias)) {
							items.push(element)
						} else if (!element.dias) {
							items.push(element)
						}
					}
				})
			})
		}

		const destaque = localStorage.getItem('destaque')

		if (!destaque && brandData && brandData.loja.b_destaques){
			localStorage.setItem('destaque', "true")
			window.onbeforeunload = () => {
				localStorage.clear()
			}

			//this.props.history.push("/destaques")
		}

		if (queryParams.includes('filtro') ){
			const filtro = queryParams.split("=")
			const catId = parseInt(filtro[filtro.length-1])
			filtrado = items.filter( item => catId === item.id_categoria )

			this.setState({ filter: catId })
		}

		this.setState({ produtos: items, lista: filtrado.length > 0 ? filtrado : items, categorias: categorias, brandData:brandData, tema:'LISTA'})
	
		try {
			await acessos()
		} catch (e) {
			console.log(e)
		}
	}

	filter(event, id) {
		if (event) event.preventDefault()
		let produtos = this.state.produtos

		if (id) {
			const categ = this.state.categorias.find(item => item.id === id)
			const show = []

			show.push(categ.id)

			categ.categorias.forEach((e) => {
				show.push(e.id)
			})

			produtos = this.state.produtos.filter(item => show.includes(item.id_categoria) )
		}

		this.setState({ filter: id, lista: produtos })
	}

	filterSubcategory(event, id) {
		event.preventDefault()

		let produtos = this.state.produtos.filter(item => item.id_categoria === id )

		this.setState({ filterSubcategory: id, lista: produtos })

		if (!id) this.filter(event, this.state.filter)
		
	}

	getSubCategories(id){
		const result = this.state.categorias.filter(function(element){
			let result = false;

			if (element.id === id){
				result = true;
			}

			return result
		})

		return result.length > 0 && result[0].categorias ? result[0].categorias : []
	}
	
	setDetalhe(){
		this.setState({showCart:true, modalPerfil:false, modalPedidos:false})
	}

	async handleSystemMenu(e, value){
		e.preventDefault()
		if (value === 'inicio') {
			if (this.context.screenSize === 'tablet'){
				this.setState({modalPerfil:false, modalPedidos: false, showCart:true })
			} else {
				this.setState({modalPerfil:false, modalPedidos: false, showCart:false, transicao: true })
				this.setState({transicao: false})
			}
			
		}

		if (value === 'pedidos') {
			this.setState({transicao: true})
			this.setState({modalPedidos: true, modalPerfil:false, showCart:false})
		}

		if (value === 'perfil') {
			this.setState({transicao: true})
			this.setState({modalPerfil: true, modalPedidos: false, showCart:false})
		}
	}

	async handleCart(){
		this.setState({modalPerfil: false, modalPedidos: false, showCart:true})
	}
	
	loading(valor){
		this.setState({transicao: valor})
	}

	render() {
		const brand = this.state.brandData
		const img = brand && brand.loja.imagem && brand.loja.imagem.imagem ? brand.loja.imagem.imagem : brand && brand.imagem_perfil ? brand.imagem_perfil.imagem : ""
		return (
			<div className="vh-100">
				<div className='pos pos-customer pos-mobile-sidebar-toggled' id="pos-customer">
					<div className="pos-menu" style={{overflowY: "hidden"}}>
						<div className="logo">
							{this.state.brandData && (
								<Link to={this.state.brandData.loja.b_home ? "/home" : "/"}>
									<div className="logo-img"><img alt="" style={{borderRadius: "10px"}} src={"https://grubupload.s3.amazonaws.com/"+img} /></div>
									<div className="logo-text" style={{textAlign: "center", textTransform: "uppercase"}}>{this.state.brandData.nome_fantasia}</div>
								</Link>
							)}
						</div>
						<div className="nav-container">
							<div className="height-full">
								<ul className="nav nav-tabs">
								{brand && brand.loja.b_destaques && this.context.dinein ? (
									<li key="categ_1000" className="nav-item">
										<Link to="/destaques" className={"nav-link"}  style={{height: "100%"}}>
											<i className="fa fa-solid fa-star"></i>DESTAQUES
										</Link>
									</li>
								) : (<></>)}
									<li key="categ_0" className="nav-item">
										<Link to="#" className={"nav-link " + (!this.state.filter ? "active" : "")} onClick={(event) => this.filter(event, null)}>
											<i className="fa fa-fw fa-utensils mr-1 ml-n2"></i>TODOS
										</Link>
									</li>
									
									{this.state.categorias && this.state.categorias.map((item,i) =>
										<li key={"categ_"+item.id} className="nav-item">
											<Link to="#" className={"nav-link " + (item.id === this.state.filter ? "active" : "")} data-filter={item.id} onClick={(event) => this.filter(event, item.id)}>
												<img src={"https://grubicons.s3.us-east-1.amazonaws.com/"+item.icon} className="fa fa-fw mr-1 ml-n2"/> {item.nome.toUpperCase()}
											</Link>
										</li>
									)}
								</ul>
							</div>
						</div>
					</div>
					
					<div className="pos-content">
						<PerfectScrollbar className="pos-content-container" options={{suppressScrollX: true}}>
							{this.getSubCategories(this.state.filter).length > 0 && (
								<ul style={{padding:0}}>
									<li key="0"className="btn btn-white me-1 mb-1" style={{color: 'black', marginRight: '10px'}} >
										<Link to="#" className={"nav-link " + (!this.state.filterSubcategory ? "active" : "")}  onClick={(event) => this.filterSubcategory(event, null)}>
											TODOS
										</Link>
									</li>
									{this.state.filter && (this.getSubCategories(this.state.filter).map((item, i) => 
										<li key={"subcateg"+item.id} className="btn btn-white me-1 mb-1" style={{color: 'black', marginRight: '10px'}} >
											<Link to={"/"+item.id} className={"nav-link " + (item.id === this.state.filterSubcategory ? "active" : "")}  onClick={(event) => this.filterSubcategory(event, item.id)}>
												{item.nome.toUpperCase()}
											</Link>
										</li>
									))}
								</ul>
							)}

							{this.state.lista && (
								<ProductList lista={this.state.lista} filter={this.state.filter}/>
							)}
						</PerfectScrollbar>
					</div>

					{this.state.delivery && this.state.showCart && this.context.carrinho && !this.state.modalPerfil && !this.state.modalPedidos &&(
						<SideBar loading={this.loading} />
					)}

					{!this.state.delivery && this.state.showCart && this.context.carrinho && this.context.mesa && !this.state.modalPerfil && !this.state.modalPedidos &&(
						<SideBarDinein showHistorico={false} loading={this.loading} backhome={this.handleSystemMenu} />
					)}

					{this.context.itensCarrinho && this.context.itensCarrinho.length > 0 && this.context.screenSize === 'mobile' && !this.state.modalPerfil && !this.state.modalPedidos &&(
						<CtaCompra handleCart={this.handleCart} />
					)}

					{this.state.modalPerfil && !this.state.modalPedidos && (
						<Perfil loading={this.loading}/>
					)}
					
					{this.state.delivery && this.state.modalPedidos && (
						<Pedidos setDetalhe={this.setDetalhe} loading={this.loading} />
					)}

					{!this.state.delivery && this.state.modalPedidos && (
						<SideBarDinein showHistorico={true} loading={this.loading} backhome={this.handleSystemMenu} />
					)}

					{this.context.carrinho && (
						<div className="pos-sidebar" style={{top: "unset", display:"block", height: "10%", background:"#fff", position: "fixed", zIndex:"1030", padding:"13px",right: "0", bottom: "0"}}>
							<div className="row">
								<div className='col-4' style={{textAlign:"center",textDecoration: "none"}} onClick={(e) => this.handleSystemMenu(e, 'inicio')}>
									<div><i className="fa-solid fa-list" style={{fontSize: "20px", color:"#64778d"}}></i></div>
									<strong>CARDÁPIO</strong>
								</div>
								<div className='col-4' style={{textAlign:"center"}} onClick={(e) => this.handleSystemMenu(e, 'pedidos')}>
									<div><i className="fa-solid fa-receipt" style={{fontSize: "20px", color:"#64778d"}}></i></div>
									<strong>PEDIDOS</strong>
								</div>
								{this.state.delivery && (
									<div className='col-4' style={{textAlign:"center"}} onClick={(e) => this.handleSystemMenu(e, 'perfil')}>
										<div><i className="fa-solid fa-user" style={{fontSize: "20px",color:"#64778d"}}></i></div>
										<strong>PERFIL</strong>
									</div>
								)}
							</div>
						</div>
					)}

					{this.state.transicao && (
						<div className="pos-sidebar" style={{top: "unset", display:"block", height: "70px", background:"#fff", position: "fixed", zIndex:"1030", padding:"10px",right: "0", top: "0"}}>
							<Lottie animationData={lottiesempedidos} loop={true} style={{width: "100%", margin:"auto"}} />
						</div>
					)}

				</div>
			</div>
		)
		
	}
}